import { createWebHistory, createRouter } from "vue-router";
import PageLayout from "@/layouts/PageLayout";

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/home/Home.vue"),
    },
    {
        path: "/page",
        name: "Page",
        component: PageLayout,
        children: [
            {
                path: "/about",
                name: "About",
                meta: { title: "IMlab | About us", pageTitle: "ABOUT US" },
                component: () => import("@/views/about/About.vue"),
            },
            {
                path: "/news",
                name: "NewsList",
                meta: { title: "IMlab | News", pageTitle: "NEWS" },
                component: () => import("@/views/news/NewsList.vue")
            },
            {
                path: "/news/:id",
                name: "News",
                meta: { title: "IMlab | News", pageTitle: "NEWS" },
                component: () => import("@/views/news/News.vue")
            },
            {
                path: "/project",
                name: "ProjectList",
                meta: { title: "IMlab | Project", pageTitle: "PROJECT" },
                component: () => import("@/views/project/ProjectList.vue")
            },
            {
                path: "/project/:id",
                name: "Project",
                meta: { title: "IMlab | Project", pageTitle: "PROJECT" },
                component: () => import("@/views/project/Project.vue")
            },
            {
                path: "/publication",
                name: "Publication",
                meta: { title: "IMlab | Publication", pageTitle: "PUBLICATION" },
                component: () => import("@/views/publication/Publication.vue")
            },
            {
                path: "/members",
                name: "Members",
                meta: { title: "IMlab | Members", pageTitle: "MEMBERS" },
                component: () => import("@/views/member/Members.vue")
            }
        ]
    },
    {
        path: "/:catchAll(.*)",
        redirect: "/"
    }
];

const setTitle = function (router) {
    router.beforeEach((to, from, next) => {
        if (to.meta.title) {
            document.title = to.meta.title;
        }
        next();
    });
};

const router = createRouter({
    history: createWebHistory(),
    routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ top: 0, behavior: 'smooth' })
            }, 250)
        })
    },
});

setTitle(router);

export default router;