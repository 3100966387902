<template>
    <button
        class="p-p-3"
        :style="style"
        v-on="$attrs"
        @mouseover="setIsHovering"
        @mouseleave="setIsHovering"
    >
        {{ text }}
    </button>
</template>

<script>
    import { ref, computed } from 'vue';

    export default {
        name: 'CustomButton',
        props: {
            width: String,
            text: String,
            bgColor: String,
            borderColor: String
        },
        setup(props, { emit }) {
            let isHovering = ref(false);
            let style = computed(() =>
                isHovering.value ?
                    { color: props.bgColor, backgroundColor: props.borderColor, borderColor: props.borderColor, width: props.width ? props.width : '300px' } :
                    { color: props.borderColor, backgroundColor: props.bgColor, borderColor: props.borderColor, width: props.width ? props.width : '300px' });

            const onClick = function () {
                emit('button-click');
            };

            const setIsHovering = function () {
                isHovering.value = !isHovering.value;
            };

            return { style, isHovering, onClick, setIsHovering };
        }
    }
</script>

<style lang="scss" scoped>
    button {
        outline: none;
        border-style: solid;
        font-size: 16px;
        transition: 0.25s;
        &:hover {
            cursor: pointer;
        }
    }
</style>