<template>
    <div class="content-container">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'ContentContainer'
    }
</script>

<style scoped lang="scss">
    .content-container {
        padding: 150px 20px;
    }
    /*  md  */
    @media screen and (min-width: 768px) {
        .content-container {
            padding: 150px 50px;
        }
    }
    /*  lg  */
    @media screen and (min-width: 960px) {
        .content-container {
            padding: 150px;
        }
    }
</style>