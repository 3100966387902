<template>
    <sidebar
        v-model:visible="isToggled"
        position="right"
        class="sidebar p-p-6"
        :auto-z-index="false"
        :show-close-icon="false"
    >
        <div class="menu-items">
            <router-link
                v-for="(item, index) in menuItems"
                :key="`nav-${index}`"
                :to="item.path"
                class="menu-item p-d-block p-m-5"
                @click="isToggled = false"
            >
                {{ item.text }}
            </router-link>
        </div>
        <div class="menu-button">
            <!--TODO: add goTo event-->
            <custom-button class="p-ml-4" text="MEMBER LOGIN" bg-color="white" border-color="#4878b0"></custom-button>
        </div>
    </sidebar>
    <slot name="toggler"></slot>
</template>

<script>
    import { ref, watch } from "vue";
    import Sidebar from 'primevue/sidebar';
    import CustomButton from "../common/CustomButton";

    export default {
        name: 'SideMenu',
        components: { Sidebar, CustomButton },
        props: {
            isShowed: Boolean
        },
        setup(props, { emit }) {
            let isToggled = ref(props.isShowed);
            watch(() => props.isShowed,() => {
                isToggled.value = props.isShowed;
            });

            watch(() => isToggled.value, (newVal) => {
                emit('close', newVal);
            });

            const menuItems = [
                { text: 'HOME', path: '/' },
                { text: 'ABOUT', path: '/about' },
                { text: 'NEWS', path: '/news' },
                { text: 'PROJECT', path: '/project' },
                { text: 'PUBLICATION', path: '/publication' },
                { text: 'MEMBERS', path: '/members' },
            ];

            return { isToggled, menuItems };
        },
        emits: ['close']
    }
</script>

<style lang="scss" scoped>
    .toggler {
        z-index: 1000;
    }

    .sidebar {
        z-index: 1000;
        min-width: 600px;
    }

    .menu-items {
        padding-top: 60px;
        .menu-item {
            font-size: 48px;
        }
    }

    .menu-button {
        margin-top: 100px;
        &:hover {
            cursor: pointer;
        }
    }
</style>
