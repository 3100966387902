<template>
    <div>
        <page-banner :text="$route.meta.pageTitle"></page-banner>
        <content-container>
            <router-view v-slot="{ Component }">
                <transition name="fade">
                    <component :is="Component" />
                </transition>
            </router-view>
        </content-container>
        <custom-footer></custom-footer>
    </div>
</template>

<script>
    import ContentContainer from "@/components/common/ContentContainer";
    import PageBanner from "@/components/common/PageBanner";
    import CustomFooter from "@/components/common/CustomFooter";

    export default {
        name: 'PageLayout',
        components: { PageBanner, ContentContainer, CustomFooter },
    }
</script>