<template>
    <side-menu :is-showed="isToggled" @close="changeToggleState">
      <template v-slot:toggler>
        <div
            class="menu"
            :class="{ 'close-menu': isToggled }"
            @click="clickToggler"
        >
          <div class="ordinator">
            <div class="line top p-mb-2"></div>
            <div class="line middle p-mb-2"></div>
            <div class="line bottom"></div>
          </div>
        </div>
      </template>
    </side-menu>
    <router-view/>
</template>

<script>
import { ref } from "vue";
import SideMenu from "./components/sideMenu/SideMenu";

export default {
    name: 'App',
    components: { SideMenu },
    setup() {
      const isToggled = ref(false);

      const clickToggler = function() {
        isToggled.value = !isToggled.value;
      };

      const changeToggleState = function (value) {
        isToggled.value = value;
      };

      return { isToggled, clickToggler, changeToggleState };
    }
  }
</script>

<style lang="scss">
  * {
    font-family: 'Josefin Sans', sans-serif, 'Noto Sans TC', sans-serif;
    letter-spacing: 0.8px;
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  a {
    color: $color-main;
    text-decoration: none;
    transition: 0.25s;
    &:hover {
      color: $color-main-dark;
    }
  }

  .main {
    background-color: $color-main;
  }

  .white {
    background-color: white;
  }

  .second {
    background-color: $color-second;
  }

  .second-l {
    background-color: $color-second-light;
  }

  .text-main {
    color: $color-main;
  }

  .text-white {
    color: white;
  }

  .text-second {
    color: $color-second;
  }

  .text-second-l {
      color: $color-second-light;
  }

  .text-second-d {
    color: $color-second-dark;
  }

  .border-main {
    border: 2px solid $color-main;
  }

  .border-white {
      border: 2px solid white;
  }

  .border-left-white {
      border-left: none;
      border-top: 2px solid white;

      @media (min-width: 580px) {
          border-top: none;
          border-left: 2px solid white;
      }
  }

  .border-left-main {
    border-left: none;
    border-top: 2px solid $color-main;

    @media (min-width: 580px) {
      border-top: none;
      border-left: 2px solid $color-main;
    }
  }

  .border-bottom-white {
      border-bottom: 2px solid white;
  }

  .border-bottom-second {
    border-bottom: 2px solid $color-second;
  }

  .border-bottom-second-d {
    border-bottom: 2px solid $color-second-dark;
  }

  .page {
    min-height: 100vh;
  }

  .menu {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 50px;
    height: 50px;
    z-index: 1000;

    .ordinator {
      width: 50px;
      height: 50px;
      .line {
        width: 50px;
        height: 5px;
        background-color: white;
        transition: 0.5s;
      }
    }
  }

  .close-menu {
    .ordinator {
      .line {
        background-color: $color-main;
      }
      .top {
        transform: translateY(14px) rotate(45deg);
      }
      .middle {
        display: none;
      }
      .bottom {
        transform: translateY(50%) rotate(-45deg);
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
