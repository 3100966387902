import App from './App.vue';
import { createApp } from 'vue';
import router from "@/router";
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons
import 'primeflex/primeflex.css';

import store from '@/store.js';

createApp(App)
    .use(PrimeVue)
    .use(router)
    .use(store)
    .mount('#app');
