import { createStore } from 'vuex'

const store = createStore({
    state: {
      projects:[]
    },
    mutations: {
      setImage (state, obj) {
        if (state.projects.some((value) => {return value.id === obj.id})){
          return;
        }
        state.projects.push(obj);
      },
    }
  })

  export default store;