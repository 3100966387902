<template>
    <content-container class="main">
        <div class="content">
            <h3 class="text-white">Still Have questions?<br/>Welcome to contact us!</h3>
            <div class="white p-d-flex p-flex-column p-flex-sm-row p-jc-between p-py-5">
                <div class="contact-sec prof-contact p-px-5 p-mb-3">
                    <h4 class="text-main p-mt-0 p-mb-3">Professor Tsai-Yen Li</h4>
                    <a class="p-d-block text-main p-mb-2" href="mailto:li@nccu.edu.tw">li@nccu.edu.tw</a>
                    <a class="p-d-block text-main" href="tel:+886 2 2938-7642">+886 2 2938-7642(Dept. of Computer Science)</a>
                </div>
                <div class="border-left-main"></div>
                <div class="contact-sec lab-contact p-px-5 p-mt-3 p-mt-sm-0">
                    <h4 class="text-main p-mt-0 p-mb-3">IMlab</h4>
                    <a class="p-d-block text-main p-mb-2" href="mailto:webmaster@imlab.cs.nccu.edu.tw">webmaster@imlab.cs.nccu.edu.tw</a>
                    <a class="p-d-block text-main" href="tel:+886 2 2939-3091">+886 2 2939-3091 Ext: 62266</a>
                </div>
            </div>
            <img class="p-d-none p-d-sm-block" src="/images/man-contact.svg" alt="man-contact image">
        </div>
    </content-container>
</template>

<script>
    import ContentContainer from "@/components/common/ContentContainer";
    export default {
        name: 'CustomFooter',
        components: { ContentContainer },
    }
</script>

<style scoped lang="scss">
    h3 {
        font-size: 40px;
    }

    h4 {
        font-size: 20px;
    }

    .content {
        position: relative;
        img {
            position: absolute;
            right: 0;
            height: 200px;
            top: -30px;
        }

        @media (min-width: 960px) {
            img {
                height: 360px;
                top: -60px;
                right: -50px;
            }
        }

        @media (min-width: 1100px) {
            img {
                height: 400px;
                top: -100px;
            }
        }
        .contact-sec {
            flex: 1;
        }
    }
</style>