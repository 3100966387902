<template>
    <content-container class="main page-banner">
        <h2 class="p-text-center text-white p-py-6">{{ text }}</h2>
    </content-container>
</template>

<script>
    import ContentContainer from "@/components/common/ContentContainer";
    export default {
        name: 'Members',
        components: { ContentContainer },
        props: {
            text: String
        }
    }
</script>

<style scoped lang="scss">

h2 {
    font-size: 48px;
}
</style>